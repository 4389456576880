import React from 'react';
import axios from 'axios';
import '../index.css';

class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            fullData: '',
            libraryList: [],
            channelOwner: '',
            displayName: '',
            loadShelf: 'pdf-library',
            newShelf: '',
        };
    }

    componentDidMount() { 
        if (window.location.hash === '') {
            this.flowShelf()}
            else {
                this.initialShelf();
            };
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);}

    swapShelf() {
        window.location.hash = document.getElementById('newArrivals').value.split("/").pop();
        this.setState(
            { newShelf: document.getElementById('newArrivals').value.split("/").pop()
                },
                this.cleanUpPush);
    };

    initialShelf() {
        this.setState({
            loadShelf: window.location.hash.replace('#','')
        }, () => {
            this.flowShelf();
        });
    };

    cleanUpPush() {
        if (this.state.newShelf !== '') {
                document.getElementById('formContainer').reset();
                this.setState({ loadShelf: this.state.newShelf }, function() {
                    return this.flowShelf()});
                document.getElementById('topBlock').scrollIntoView({ behavior: 'smooth' });}
        else {}
    };

    jumpDown() {
        document.getElementById('bottomBlock').scrollIntoView({ behavior: 'smooth' })}

    flowShelf() {
        axios.get('https://api.are.na/v2/channels/' + this.state.loadShelf)
        .then(response => {
            this.setState({ fullData: response.data });
            this.setState({ libraryList: response.data.contents });
            this.setState({ channelOwner: response.data.owner }, function() {
                if (this.state.channelOwner.class === 'User') {
                    this.setState({ displayName: this.state.channelOwner.first_name })}
                    else {
                        this.setState({ displayName: this.state.channelOwner.name })};
            });
        });
    };

    onKeyPress(event) {
        if (event.which === 13) {
          event.preventDefault();
        };
    };

    render() {

        var colors = ['tan', 'beige', 'grey','coral','darkslateblue'];

        var classRotations = ['bookItem plus-one','bookItem minus-one','bookItem plus-two','bookItem minus-two'];

        return (
            <div id="container">
                <div id="topBlock">
                <div id="topText">
                    <p className="leftColumn">Shelf L.ink</p>
                    <p className="rightColumn">Browsing <a target="_blank" rel="noopener noreferrer" href={'https://are.na/' + this.state.channelOwner.slug}>{this.state.displayName}'s</a> shelf; <span id="lookElse" onClick={this.jumpDown}>look elsewhere.</span></p>
                    </div>

                <div id="shelf">
                    {
                    this.state.libraryList
                        .filter(props => props.attachment != null)
                        .filter(props => props.attachment.extension === "pdf")
                        .map(props => {
                            return (
                                <a key={props.id} href={props.attachment.url} target="_blank" rel="noopener noreferrer"><div style={{ backgroundColor: colors[Math.floor(Math.random() * colors.length)] }} className={classRotations[Math.floor(Math.random() * classRotations.length)]}><p>{props.title}</p></div></a>
                            )
                        })
                    }
                </div>
                </div>

                <div id="bottomBlock">
                    <div id="bottomText">
                        <p className="leftColumn">A more library-like experience for PDF collections.</p>
                        <p className="rightColumn">
                            Paste in an Are.na channel URL to repopulate the shelf.</p>
                        <form onKeyPress={this.onKeyPress} id="formContainer" className="rightColumn">
                            <input id="newArrivals" type="text" onsubmit="return false;" />
                            <input id="flowArrivals" type="button" value="Refresh" onClick={() => this.swapShelf()} />
                            </form>
                    </div>

                    <p id="credits">Site by <a href="https://loganheffernan.com">Logan Heffernan</a></p>
                </div>
            </div>
        )
    }
}

export default App;